<template>
   <div class="bg-sponge-navy fixed bottom-0 w-full text-sponge-navy-contrast p-4" v-show="show">
       <div class="sm:flex max-w-4xl mx-auto">
         <div class="text-center sm:text-left md:text-center mb-6 sm:mb-0 sm:grow sm:pr-16">
           <p>Sponge (and selected third parties) uses cookies and other technologies to improve your experience and for personalised ads & content. <a href="/privacy-policy" class="underline" target="_blank">More Info</a>.</p>
         </div>

         <div class="flex space-x-12 flex-none justify-center items-center pb-2 sm:pb-0">
           <div>
             <button class="font-bold underline text-sm" @click="show_form = !show_form">Customise</button>
           </div>

<!--           <Button color="white" size="sm"  ></Button>-->
           <Button color="yellow" class="focus:bg-green-500" @click="allow_all" >Accept All</Button>
         </div>
       </div>
   </div>

  <Dialog :open="show_form">
    <h2 class="bg-sponge-navy text-sponge-navy-contrast font-heading text-center text-lg py-2">Customise consent</h2>

    <form action="" class="py-2 px-6" @submit.prevent="update">
        <div v-for="(category, key) in categories" class="flex items-center my-4">
          <div>
            <input type="checkbox" v-model="selected_categories" :id="key" :disabled="category.always_checked" :value="key">
          </div>

          <label :for="key" class="block ml-2 grow text-sm">
            <strong>{{ category.label }}</strong><br>
            {{category.description}}
          </label>
        </div>

        <div class="mt-8 mb-4 text-center">
          <Button color="yellow" type="submit">Save</Button>
        </div>
    </form>
  </Dialog>
</template>

<script setup>
  import Cookies from "js-cookie";
  import Button from "@SpongeFoundation/vue/Shop/Button/Button.vue";
  import Dialog from "@SpongeFoundation/vue/Common/Dialog/Dialog.vue";
  import {computed, reactive, ref} from "vue";

  let categories = {
      necessary: {label: "Necessary", description: "Enables storage to make the site work, this can\'t be disabled.", signals: [], always_checked: true},
      advertising: {label: "Advertising", description: "Enables storage (such as cookies) related to advertising, including user data related to advertising.", signals: ["ad_storage","ad_user_data"], always_checked: false},
      analytics: {label: "Analytics", description: "Enables storage (such as cookies) related to analytics e.g. visit duration.", signals: ["analytics_storage"], always_checked: false},
      functionality: {label: "Functionality", description: "Enables storage that supports the functionality of the website or app e.g. language settings.", signals: ["functionality_storage"], always_checked: false},
      personalisation: {label: "Personalisation", description: "Enables consent for personalized advertising and other types of personalization e.g. video recommendations.", signals: ["ad_personalization_storage","personalization_storage"], always_checked: false}
  };

  // we may want to handle the current state here from the window object
  // but we would need to reconcile the signals to our categories...
  let selected_categories = ref(["necessary"]);

  let computed_consent = computed(() => {
    let consent = {};

    // essentially loop through all of our category data and see if we've granted it or not
    Object.entries(categories).forEach(([category_key, category_data]) => {
      category_data.signals.forEach((signal) => {
        consent[signal] = selected_categories.value.lastIndexOf(category_key) !== -1 ? 'granted' : 'denied';
      });
    })

    return consent;
  });

  const cookie = Cookies.get('consent');
  let show = ref(typeof cookie === "undefined");
  let show_form = ref(false);

  const update = () => {
    // even though we're reloading the page, maybe we wont in future..
    window.consent = computed_consent.value;

    // because bing
    if(typeof window.uetq !== 'undefined') {
      Object.entries(computed_consent.value).forEach(([signal, consent]) => {
        if(signal === 'ad_storage') {
          window.uetq.push('consent', 'update', {ad_storage: consent});
        }
      });
    }

    Cookies.set('consent', JSON.stringify(computed_consent.value), { expires: 365 });
    gtag('event', 'consent_change');
    window.location.reload();
  }

  const allow_all = () => {
    // just loop through all the cat keys and add them to selected
    selected_categories.value = [];
    Object.entries(categories).forEach(([category_key]) => selected_categories.value.push(category_key));
    update();
  }

</script>