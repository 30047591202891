<template>
  <Header
      :logo="!club ? '/img/base/logo.svg' : '/img/base/logo-club.svg'"
      account_link="/account"
      basket_link="/basket"
      help_link="/help"
      :search_button="true"
      @search="search"
      :basket_count="basket_count"
      :calls_to_action="calls_to_action"
      :items="items"
  >
  </Header>

  <AutocompleteModal
      ref="search_modal"
      :app_key="search_app_key"
      :api_key="search_api_key"
      :index_prefix="search_index_prefix"
  />
</template>

<script setup lang="ts">
  import Header from "@SpongeFoundation/vue/Shop/Header/Header.vue";
  import {ref, withDefaults} from "vue";
  import AutocompleteModal from "@/components/Site/Search/AutocompleteModal.vue";

  const props = withDefaults(defineProps<{
    basket_count?: string,
    club?: boolean,
    calls_to_action?: string[],
    search_api_key: string,
    search_app_key: string,
    search_index_prefix: string,
  }>(), {
    basket_count: '0',
    club: false,
    calls_to_action() { return []; },
  });

  const search_modal = ref(null);
  const search = () => search_modal.value.open();

  const items = {
    left: [
      {
        name: 'Products',
        icon: '/img/base/nav-mobile-products.svg',
        href: null,
        description: null,
        new: false,
        children: [
          {name: 'Whole Cakes', icon: '/img/base/nav-wholecakes.svg', description: 'Delicious Cakes', href: '/cakes/whole-cakes', new: false, children: null},
          {name: 'Sharing Cakes', icon: '/img/base/nav-sharingcakes.svg', description: 'Try all the flavours', href: '/cakes/sharing-cakes', new: false, children: null},
          {name: 'Cake Cards', icon: '/img/base/nav-cakecards.svg', description: 'Cake in a card, through the letterbox!', href: '/cake-card', new: false, children: null},
          {name: 'Baby Sponge Cakes', icon: '/img/base/nav-babies.svg', description: 'A fresh take on cupcakes', href: '/cakes/baby-sponges', new: false, children: null},
          // {name: 'Decorating Kits', icon: '/img/base/nav-decoratingkits.svg', description: 'Ready, set, decorate!', href: '/cakes/decorating-kits', new: true, children: null},
          {name: 'Brownies', icon: '/img/base/nav-brownies-v2.svg', description: 'Our all new letterbox brownies are here!', href: '/brownies', new: false, children: null},
          {name: 'Photo Cakes', icon: '/img/base/nav-personalisedcakes.svg', description: 'Get creative', href: '/cakes/personalised-cakes', new: false, children: null},
          {name: 'Sponge Of The Week', icon: '/img/base/nav-spongefriday.svg', description: 'A weekly cake on offer!', href: '/sponge-of-the-week', new: false, children: null},
          {name: 'Gift Vouchers', icon: '/img/base/nav-evouchers.svg', description: 'Can\'t choose? need a last minute gift?', href: '/gift-vouchers', new: false, children: null},
          {name: 'Added Extras', icon: '/img/base/nav-addedextras.svg', description: 'Personalise your cake', href: '/added-extras', new: false, children: null}
        ]
      },
      {
        name: 'Free From',
        icon: '/img/base/nav-mobile-freefrom.svg',
        href: null,
        description: null,
        new: false,
        children: [
          {name: 'Gluten Free Cakes', icon: '/img/base/nav-glutenfreecakes.svg', description: 'Delicious GF Cakes', href: '/cakes/gluten-free-cakes', new: false, children: null},
          {name: 'Gluten Free Brownies', icon: '/img/base/nav-glutenfreebrownies.svg', description: 'Gooey GF brownies', href: '/cakes/gluten-free-brownies', new: false, children: null},
          {name: 'Vegan Cakes', icon: '/img/base/nav-vegancakes.svg', description: 'Delicious vegan cakes', href: '/cakes/vegan-cakes', new: false, children: null},
          // {name: 'Vegan Brownies', icon: '/img/base/nav-veganbrownies.svg', description: 'Gooey vegan brownies', href: '', new: false, children: null},
          // {name: 'Vegan Cheesecakes', icon: '/img/base/nav-vegancheesecakes.svg', description: 'Yummy vegan cheesecakes', href: '/cakes/vegan-cheesecakes', new: false, children: null},
          {name: 'Dairy Free Cakes', icon: '/img/base/nav-dairyfreecakes.svg', description: 'Delicious DF cakes', href: '/cakes/dairy-free-cakes', new: false, children: null},
          {name: 'Gluten & Dairy Free Cakes', icon: '/img/base/nav-glutenfreedairyfreecakes.svg', description: 'Delicious GF and DF Cakes', href: '/cakes/gluten-and-dairy-free-cakes', new: false, children: null},
          {name: 'No Refined Sugar', icon: '/img/base/nav-norefinedsugar.svg', description: 'Indulgent Cakes without refined sugar', href: '/cakes/no-refined-sugar-cakes', new: false, children: null},
          {name: 'No Buttercream', icon: '/img/base/nav-nobuttercream.svg', description: 'Cakes without the buttercream', href: '/cakes/no-buttercream-cakes', new: false, children: null},
        ]
      },
      {
        name: 'Cake Card',
        icon: '/img/base/nav-cakecards.svg',
        href: '/cake-card',
        description: null,
        new: false,
        children: null
      }
    ],
    right: [
      {
        name: 'Occasions',
        icon: '/img/base/nav-mobile-occasions.svg',
        href: null,
        description: null,
        new: false,
        children: [
          {name: 'Christmas', icon: '/img/base/nav-christmas.svg', description: 'Festive bakes', href: '/occasions/christmas', new: true, children: null},
          // {name: 'Anniversary Cakes', icon: '/img/base/nav-anniversary.svg', description: 'Special Sponge', href: '/occasions/anniversary-cakes', new: false, children: null},
          {name: 'Celebration Cakes', icon: '/img/base/nav-celebration.svg', description: 'Celebrate in style', href: '/occasions/celebration-cakes', new: false, children: null},
          // {name: 'Halloween Cakes', icon: '/img/base/nav-halloween.svg', description: 'No tricks, just treats', href: '/occasions/halloween', new: true, children: null},
          {name: 'Birthday Cakes', icon: '/img/base/nav-birthday.svg', description: 'Say Happy Birthday with Sponge', href: '/occasions/birthday-cakes', new: false, children: null},
          // {name: 'Fathers Day', icon: '/img/base/nav-fathersday.svg', description: 'Gifts for dad', href: '/occasions/fathers-day', new: true, children: null},
          {name: 'Kids Birthday Cakes', icon: '/img/base/nav-kidsbirthday.svg', description: 'Birthday fun', href: '/occasions/kids-birthday-cakes', new: false, children: null},
          // {name: 'Teacher Gifting', icon: '/img/base/nav-graduation.svg', description: 'Say thanks with Sponge', href: '/occasions/thank-you-teacher-gifts', new: true, children: null},
          // {name: 'Mothers Day', icon: '/img/base/nav-mothersday.svg', description: 'Treat your mum', href: '/occasions/mothers-day', new: true, children: null},
          // {name: 'Valentines', icon: '/img/base/nav-valentines.svg', description: 'Say I love you with Sponge', href: '/occasions/valentines', new: true, children: null},
          // {name: 'Easter', icon: '/img/base/nav-easter.svg', description: 'Eggcellent bakes', href: '/occasions/easter', new: true, children: null},
          {name: 'Wedding Cakes', icon: '/img/base/nav-weddings.svg', description: 'Wedding packages & gifts', href: '/cakes/weddings', new: false, children: null},
        ]
      },
      {
        name: 'Club',
        icon: '/img/base/logo-club.svg',
        href: '/club',
        description: null,
        new: false,
        children: null
      },
      {
        name: 'Wholesale & Corporate',
        icon: '/img/base/nav-tradecorporate.svg',
        href: null,
        description: null,
        new: false,
        children: [
          {name: 'Wholesale', icon: '/img/base/nav-trade.svg', href: '/trade', description: 'Become a wholesale customer', new: false, children: null},
          {name: 'Corporate', icon: '/img/base/nav-corporate.svg', href: '/corporate', description: 'Corporate gifting options', new: false, children: null}
        ]
      }
    ]
  }
</script>