<template>
  <div ref="ac_container"></div>
  <div ref="panel_container" class="p-2"></div>
</template>

<script setup lang="js">

import algoliasearch from 'algoliasearch/lite';
import { autocomplete, getAlgoliaResults } from '@algolia/autocomplete-js';
import {onMounted, ref, h, Fragment, render, onUnmounted} from "vue";
import ProductResult from './ProductResult.vue';
import PageResult from './PageResult.vue';
import DesignResult from './DesignResult.vue';

let ac_container = ref(null);
let panel_container = ref(null);
let autocompleteSearch = null;

const props = defineProps({
  api_key: {Type: String, default: null},
  app_key: {Type: String, default: null},
  index_prefix: {Type: String, default: 'prod'},
  debug: {Type: Boolean, default: false}
});

const searchClient = algoliasearch(props.app_key, props.api_key);

// For the debouncing
// https://www.algolia.com/doc/ui-libraries/autocomplete/guides/debouncing-sources/
function debouncePromise(fn, time) {
  let timerId = undefined;

  return function debounced(...args) {
    if (timerId) {
      clearTimeout(timerId);
    }

    return new Promise((resolve) => {
      timerId = setTimeout(() => resolve(fn(...args)), time);
    });
  };
}

const debounced = debouncePromise((items) => Promise.resolve(items), 300);

const navigator = {
  navigate({itemUrl, item}) {
    window.location.assign(`${itemUrl}?via=search&choice=${typeof item.id !== 'undefined' ? item.id : item.objectId}`);
  }
}

onMounted(() => {
  autocompleteSearch = autocomplete({
    container: ac_container.value,
    renderer: { createElement: h, Fragment, render },
    debug: props.debug,
    panelContainer: panel_container.value,
    panelPlacement: 'full-width',
    detachedMediaQuery: 'none',
    navigator: navigator,
    placeholder: 'Search for something, eg \'victoria\' or \'delivery\'',
    id: 'sponge-search',
    plugins: [],
    shouldPanelOpen() {
      return true;
    },
    renderNoResults({ state, render, html }, root) {
      render(html`
        <div class="p-8 text-center prose text-gray-600"><p class="font-bold">No results found.</p><p class="">We could not find any results matching that term. Please check your query and try again.</p></div>
      `, root);
    },
    render({ elements, render, html }, root) {
      const {products, pagecontent, designs} = elements;

      render(html`
         <div class="px-2">
            ${products}
            ${pagecontent}
            ${designs}
         </div>
      `, root);
    },
    getSources() {
      return debounced([
        {
          sourceId: 'products',
          getItemInputValue: ({ item }) => item.query,
          onSelect({item}) {
            navigator.navigate({itemUrl: item.url, item: item});
          },
          getItems({ query }) {
            return getAlgoliaResults({
              searchClient,
              queries: [
                {
                  indexName: props.index_prefix + '_products',
                  query,
                  params: {
                    hitsPerPage: 5,
                    attributesToSnippet: ['name:10', 'description:35'],
                    snippetEllipsisText: '…',
                  },
                },
              ],
            });
          },
          getItemUrl({ item }) {
            return item.url;
          },
          templates: {
            header({html, state}) {
              //console.log(state);
              return html`<div class="bg-gray-100 py-1 px-2 text-center text-xs rounded-sm font-bold mx-auto">From our product range</div>`;
            },
            item({item, components}) {
              // it would all get rather messy (and we want to use components from our library) to put the html
              // for the product display here, so we'll delegate to vue's hyperscript renderer, and use a custom component
              // passing the search data as a prop. We can even utilise algolia's highlighter via slots.
               return h(ProductResult, {item: item}, {
                    highlight_name: () => components.Highlight({ hit: item, attribute: 'name' }),
                    description: () => components.Snippet({ hit: item, attribute: 'description' })
               });
            },
          },
        }, {
          sourceId: 'pagecontent',
          getItemInputValue: ({ item }) => item.query,
          onSelect({item}) {
            navigator.navigate({itemUrl: item.url, item: item});
          },
          getItems({ query }) {
            return getAlgoliaResults({
              searchClient,
              queries: [
                {
                  indexName: props.index_prefix + '_pagecontent',
                  query,
                  params: {
                    hitsPerPage: 3,
                    attributesToSnippet: ['name:10', 'description:35'],
                    snippetEllipsisText: '…',
                  },
                },
              ],
            });
          },
          getItemUrl({ item }) {
            return item.url;
          },
          templates: {
            header({html}) {
              return html`<div class="bg-gray-100 py-1 px-2 text-center text-xs rounded-sm font-bold mt-2">From our info pages</div>`;
            },
            item({item, components}) {
              return h(PageResult, {item: item}, {
                highlight_name: () => components.Highlight({ hit: item, attribute: 'name' }),
                description: () => components.Snippet({ hit: item, attribute: 'description' })
              });
            }
          },
        }, {
          sourceId: 'designs',
          getItemInputValue: ({ item }) => item.query,
          onSelect({item}) {
            navigator.navigate({itemUrl: item.url, item: item});
          },
          getItems({ query }) {
            return getAlgoliaResults({
              searchClient,
              queries: [
                {
                  indexName: props.index_prefix + '_designs',
                  query,
                  params: {
                    hitsPerPage: 5,
                    attributesToSnippet: ['name:10', 'description:35'],
                    snippetEllipsisText: '…',
                  },
                },
              ],
            });
          },
          getItemUrl({ item }) {
            return item.url;
          },
          templates: {
            header({html}) {
              return html`<div class="bg-gray-100 py-1 px-2 text-center text-xs rounded-sm font-bold mt-2">From our designs</div>`;
            },
            item({item, components}) {
              return h(DesignResult, {item: item});
            }
          },
        },
      ]);
    },
  });
});

onUnmounted(() => {
  if(typeof autocompleteSearch.destroy !== 'undefined') {
    autocompleteSearch.destroy();
  }
});
</script>

<style lang="css">

@import "@algolia/autocomplete-theme-classic/dist/theme.css";

/*
  This is the 'reset' to undo tailwind/algolia fighting, so it looks somewhere near to the demo.
  Its not perfect due to a right border issue with the input before you type text, but its the
  least worst compromise.

  If we ever change our base theme this will need addressing.
*/


input.aa-Input {
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  box-shadow: none;
  transition: none;
  border-top: none;
  border-bottom: none;
}

input.aa-Input:focus,
input.aa-Input:active {
  border-top: 2px solid rgba(var(--aa-primary-color-rgb), var(--aa-primary-color-alpha));
  border-bottom: 2px solid rgba(var(--aa-primary-color-rgb), var(--aa-primary-color-alpha));
  box-shadow: none;
}

/** these are our customisations to make it look ours **/
:root {
  --aa-icon-color-rgb: 160,160,160;
  --aa-icon-color-alpha: 1;
  --aa-input-border-color-rgb: 128, 126, 163;
  --aa-input-border-color-alpha: 0.8;
  --aa-search-input-height: 40px;
}

button.aa-SubmitButton,
label.aa-Label {
  box-shadow: none;
  border: none;
  outline: none;
}

svg.aa-SubmitIcon {
  fill: rgba(var(--aa-icon-color-rgb), var(--aa-icon-color-alpha));
  margin: 0 auto;
}

.aa-InputWrapperPrefix label.aa-label {
  width: 12px;
}

input.aa-Input {
  /*font-size: 1rem;*/
  padding: 0.5rem;
}

.aa-Item mark {
  background: transparent;
  text-decoration: underline;
}

section.aa-Source .aa-SourceHeader {
  margin: 0 0 0.5rem 0;
}

.aa-Panel {
  z-index: 99;
  border-radius: 0;
  box-shadow: none;
  overflow-y: auto;
  height: calc(100% - 4rem);

  /* so you can't click inbetween the search input and panel to close it **/
  margin-top: 0;
  padding-top: 10px;
}

/* So the card fronts can align on a grid */
section.aa-Source[data-autocomplete-source-id="designs"] ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0;
}

</style>